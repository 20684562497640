<template>
    <div class="app-city-navigation">
        <span class="your-city" @click="$emit('openRegions')">Ваш город:
            <span class="city-name"> {{ location.currentLocation }}
                <span class="dashed-underline">
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                </span>
                <span class="city-name-triangle"></span>
            </span>
        </span>
    </div>
</template>

<script>
import { computed, reactive } from 'vue';
import { useStore } from "vuex";
    export default {
        setup(){
            const store = useStore();

            const location = reactive({
                currentLocation: computed(() => store.state.location)
            })

            return {
                location
            }
        }
    }
</script>

<style lang="scss" scoped>
    .app-city-navigation{
        font-size: 1em;
        font-weight: 700;
        .city-name{
            position: relative;
            cursor: pointer;
            .dashed-underline{
                width: 100%;
                position: absolute;
                display: block;
                left: 0;
                bottom: -10%;
                width: 100%;
                display: flex;
                justify-content: space-between;                
                span{
                    display: block;
                    width: 20%;
                    height: 2px;
                    background-color: $accent;
                }
            }
            .city-name-triangle{
                width: 0;
                height: 0;
                position: absolute;
                top: 50%;
                right: -0.9em;
                transform: translateY(-20%);
                border-top: 10px solid $accent;
                border-left: 5px solid transparent;
                border-right: 5px solid transparent;
                border-bottom: 10px solid transparent;
            }
        }
    }
</style>