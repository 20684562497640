<template>
    <div class="app-footer">
        <div class="common-blocks">
            <div class="information-block block">
                <h2 class="information-title title">Информация</h2>
                <ul>
                    <li><a target="_blank" :href="pathToStatic + 'agreement'">Пользовательское соглашение</a></li>
                    <li><a target="_blank" :href="pathToStatic + 'rules'">Правила размещения анект</a></li>
                    <li><a target="_blank" :href="pathToStatic + 'confidentials'">Политика конфиденциальности</a></li>
                    <li><a target="_blank" :href="pathToStatic + 'prise'">Условия оплаты и цены</a></li>
                    <li><a target="_blank" :href="pathToStatic + 'about'">Контакты</a></li>
                </ul>
            </div>
            <div class="contacts-block block">
                <h2 class="contacts-title title">Контакты</h2>
                <ul>
                    <li>
                        <i>
                            <a href="mailto:infoerm21@gmail.com"><AppEmailIcon /></a>
                            
                        </i>
                        <a href="mailto:infoerm21@gmail.com">infoerm21@gmail.com</a>
                    </li>
                    <li>
                        <i>
                            <AppWhatsappIcon />
                        </i>
                        <a href="">8 800 800 00 00</a>
                    </li>
                    <li>
                        <i>
                            <AppTelegrammIcon />
                        </i>
                        <a href="">@eromass</a>
                    </li>
                    <li>
                        <a target="_blank" :href="pathToStatic + 'about'">О нас</a>
                    </li>
                </ul>
            </div>
            <div class="warnings-block block">
                <div class="warning">
                    <span class="icon"><AppWarningIcon /></span>
                    <span class="text">Публикация анкет с интим-услугами запрещена!</span>
                </div>
                <div class="warning">
                    <span class="icon"><AppMedicIcon /></span>
                    <span class="text">Массаж имеет противопоказания, проконсультируйтесь со специалистом!</span>
                </div>
                <div class="warning">
                    <span class="icon-18">18+</span>
                    <span class="text">Сайт для совершеннолетних, вам должно быть больше 18 лет!</span>
                </div>
            </div>
        </div>
        <div class="sub-footer"><span>Разработка и поддержка сайта <a href="http://js-web.ru">JS-WEB.RU</a>, 2021 год</span><a href="https://freekassa.ru" target="_blank" rel="noopener noreferrer">
  <img src="https://cdn.freekassa.ru/banners/small-white-1.png" title="Прием платежей на сайте">
</a></div>
    </div>
</template>

<script>
import AppEmailIcon from "@/components/icons/app-email-icon.vue";
import AppWhatsappIcon from "@/components/icons/app-whatsapp-icon.vue";
import AppTelegrammIcon from "@/components/icons/app-telegramm-icon.vue";
import AppWarningIcon from "@/components/icons/app-warning-icon.vue";
import AppMedicIcon from "@/components/icons/app-medic-icon.vue";
import domain from "@/api/domain.js"
    export default {
        components: {
            AppEmailIcon,
            AppWhatsappIcon,
            AppTelegrammIcon,
            AppWarningIcon,
            AppMedicIcon
        },
        data(){
            return {
                pathToStatic: domain.pathToStatic
            }
        }
    }
</script>

<style lang="scss" scoped>
    .app-footer{
        background-color: $primary-dark;
        padding: 3em 0;
        color: $white;
        a{
            color: $white;
        }
        .common-blocks{
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            .block{
                width: 30%;
                min-width: 375px;
                padding: 1em;
                text-align: left;
                .title{
                    font-weight: 700;
                    font-size: 2em;
                    margin-bottom: 1em;
                }
                ul{
                    list-style: none;
                    li{
                        font-size: 1.375em;
                        font-weight: 700;
                        margin-bottom: 0.5em;
                        display: flex;
                        align-items: center;
                        i{
                            margin-right: 1em;
                        }
                        a{
                            text-decoration: none;
                        }
                    }
                }
                .warning{
                    font-size: 1.375em;
                    font-weight: 700;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    margin-bottom: 1em;
                    .text{
                        width: 70%;
                    }
                    .icon, .icon-18{
                        // margin-right: 1em;
                        width: 30%;
                    }
                    .icon-18{
                        font-size: 2em;
                    }
                }
            }
        }
        .sub-footer{
            background-color: $primary-darken;
            padding: 2em 2em 4em;
            font-size: 1em;
            font-weight: 700;
            color: $white;
            display: flex;
            justify-content: space-between;
        }
    }

    @media screen and(min-width: 768px){
        .app-footer{
            .common-blocks{
                .warnings-block{
                    min-width: 500px!important;
                }
                .block{
                    min-width: 300px;
            }
            }
        }
    }
    @media screen and(min-width: 1024px){
        .app-footer{
            padding-bottom: 0;
            .sub-footer{
                padding: 2em 2em;
               
        }
        }
    }
</style>