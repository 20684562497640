<template>
    <div class="app-main-header-navigation">
        <a href="#" class="navigation-item" @click="openWoman"><span class="item-underline"></span>Массажистки<span class="item-triangle"></span></a>
        <a class="sub-navigation woman" :class="{ 'active' : subMenuWomanMassagist }" @mouseleave="subMenuWomanMassagist = false">
            <a href="#" class="sub-navigation-item">Для мужчин<span class="item-triangle"></span></a>
            <a href="#" class="sub-navigation-item">Для женщин<span class="item-triangle"></span></a>
            <a href="#" class="sub-navigation-item">Для пар<span class="item-triangle"></span></a>
        </a>
        <a href="#" class="navigation-item" @click="openMan"><span class="item-underline"></span>Массажисты<span class="item-triangle"></span></a>
         <a class="sub-navigation man" :class="{ 'active' : subMenuManMassagist }" @mouseleave="subMenuManMassagist = false">
            <a href="#" class="sub-navigation-item">Для мужчин<span class="item-triangle"></span></a>
            <a href="#" class="sub-navigation-item">Для женщин<span class="item-triangle"></span></a>
            <a href="#" class="sub-navigation-item">Для пар<span class="item-triangle"></span></a>
        </a>
        <a href="#" class="navigation-item" @click="openSaloon"><span class="item-underline"></span>Массажные салоны<span class="item-triangle"></span></a>
         <a class="sub-navigation saloon" :class="{ 'active' : subMenuSaloonMassagist }" @mouseleave="subMenuSaloonMassagist = false">
            <a href="#" class="sub-navigation-item">Для мужчин<span class="item-triangle"></span></a>
            <a href="#" class="sub-navigation-item">Для женщин<span class="item-triangle"></span></a>
            <a href="#" class="sub-navigation-item">Для пар<span class="item-triangle"></span></a>
        </a>
    </div>
</template>

<script>
    export default {
        data(){
            return{
                subMenuWomanMassagist: false,
                subMenuManMassagist: false,
                subMenuSaloonMassagist: false,
            }
        },
        methods: {
            openWoman(){
                this.subMenuWomanMassagist = !this.subMenuWomanMassagist;
                this.subMenuManMassagist = false;
                this.subMenuSaloonMassagist = false;
            },
            openMan(){
                this.subMenuManMassagist = !this.subMenuManMassagist;
                this.subMenuWomanMassagist = false;
                this.subMenuSaloonMassagist = false;
            },
            openSaloon(){
                this.subMenuSaloonMassagist = !this.subMenuSaloonMassagist;
                this.subMenuWomanMassagist = false;
                this.subMenuManMassagist = false;
            },

        }
    }
</script>

<style lang="scss" scoped>
    .app-main-header-navigation{
        display: none;        
    }

    @media screen and(min-width: 1024px){
        .app-main-header-navigation{
            display: flex;
            padding: 0;
            position: relative;
            .active{
                display: flex!important;
            }
            a{
                display: inline;
            }
        .navigation-item{
            font-size: 0.7em;
            text-transform: uppercase;
            font-weight: 900;
            color: $black;
            margin: 0 0.8em;
            position: relative;
            cursor: pointer;
            text-decoration: none;
            display: block;
            .item-underline{
                position: absolute;
                width: 0;
                height: 2px;
                background-color: $accent;
                left: 0;
                bottom: -0.2em;
                transition: 0.5s ease;
            }
            &:hover > .item-underline{
                width: 100%;
            }
            &:focus > .item-underline{
                width: 100%;
            }
            &:focus{
                outline: none;
            }
            .item-triangle{
                width: 0;
                height: 0;
                border-top: 8px solid $accent-dark;
                border-bottom: 8px solid transparent;
                border-left: 4px solid transparent;
                border-right: 4px solid transparent;
                position: absolute;
                top: 50%;
                right: -1em;
                transform: translateY(-20%);
            }
     
        }
        .sub-navigation{
            display: none;
            position: absolute;
            padding: 0.5em 0 0.5em 0.5em;            
            flex-direction: column;
            background-color: $accent;
            border: 1px solid $white;
            z-index: 1000;
            border-radius: 0.4em;
            .sub-navigation-item{
                text-decoration: none;
                display: inline-block;
                padding: 0.5em;
                color: $white;
                font-size: 0.6em;
                text-transform: uppercase;
                font-weight: 500;
                position: relative;
                &:hover > .item-triangle{
                    transform: rotate(-90deg)
                }
                &:focus > .item-triangle{
                    transform: rotate(-90deg)
                }
                &:focus{
                    outline: none;
                }
                .item-triangle{
                    width: 0;
                    height: 0;
                    border-top: 7px solid $white;
                    border-bottom: 7px solid transparent;
                    border-left: 4px solid transparent;
                    border-right: 4px solid transparent;
                    position: absolute;
                    top: 50%;
                    right: 9%;
                    transform: translateY(-30%);
                    transition: 0.5s ease;
                    transform-origin: 10% 10%;
                }
            }
    
        }
        .woman{
            left: 0%;
            top: 130%;
            width: 30%;
        }
        .man{
            left: 30%;
            top: 130%;
            width: 30%;
        }
        .saloon{
            left: 60%;
            top: 130%;
            width: 40%;
        }
        }
    }
    @media screen and(min-width: 1250px){
        .app-main-header-navigation{
            padding: 0 1em;
        .navigation-item{
            font-size: 1em;
            font-weight: 700;
            margin: 0 1em;
            .item-underline{
                height: 4px;
            }
            .item-triangle{               
                border-top: 10px solid $accent-dark;
                border-bottom: 10px solid transparent;
                border-left: 5px solid transparent;
                border-right: 5px solid transparent;               
                transform: translateY(-30%);
            }
     
        }
        .sub-navigation{           
            padding: 1em 0 1em 1em;            
            .sub-navigation-item{
                font-size: 0.8em;
                .item-triangle{
                    border-top: 8px solid $white;
                    border-bottom: 8px solid transparent;
                    border-left: 4px solid transparent;
                    border-right: 4px solid transparent;
                }
            }
    
        }
        .woman{
            left: 5%;
            top: 130%;
            width: 30%;
        }
        .man{
            left: 30%;
            top: 130%;
            width: 30%;
        }
        .saloon{
            left: 60%;
            top: 130%;
            width: 40%;
        }
        }
    }
 
</style>